import { FC, memo, ReactNode } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'
import { Header } from '@lifedot/components/Header'
import { CopyRight } from '@lifedot/components/Footer/Footer'
import Image from 'next/image'

const styles = {
  root: css({
    background: palette.gray1
  }),
  logo: css({
    fontSize: 0,
    [mq('sp')]: {
      width: 88
    }
  }),
  body: css({
    paddingBottom: 60
  })
}

type BaseLayoutProps = {
  children: ReactNode
}

export const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <div css={styles.root}>
      <MemorizedHeader />
      <div css={styles.body}>{children}</div>
      <footer>
        <MemorizedCopyRight />
      </footer>
    </div>
  )
}

const MemorizedCopyRight = memo(CopyRight)

const BeforeMemorizedHeader: FC = () => (
  <Header
    logo={
      <div css={styles.logo} itemProp="headline">
        <Image
          src="https://s3.lifedot.jp/uploads/ending/path/52461/logo.png"
          width={132}
          height={42}
          alt="お墓・霊園探しならライフドット"
          priority
        />
      </div>
    }
  />
)

const MemorizedHeader = memo(BeforeMemorizedHeader)
