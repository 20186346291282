import { FC, useEffect } from 'react'
import { AppProps } from 'next/app'
import { RelayEnvironmentProvider } from 'relay-hooks/lib/RelayEnvironmentProvider'
import { createEnvironment } from '@lifedot/relay'
import { GlobalStyle } from '@lifedot/atoms/GlobalStyle'
import { DefaultSeo } from '@lifedot/components/Seo'
import { SingleColumn } from '@lifedot/layout'
import { Head } from '@/components/Head'
import { BaseLayout } from '@/layouts/BaseLayout'
import { BookmarkContextProvider } from '@lifedot/hooks/use-bookmark'
import { useEventTracking, usePageView } from '@lifedot/tracking'
import { nameSpace } from '@lifedot/constants/bookmarks'
import { NextPageContext } from 'next'
import { useRouter } from 'next/router'
import { TrackingCategory } from '@/constants/TrackingCategories'
import 'polyfill-object.fromentries'
import { QueryPayload } from 'react-relay-network-modern'

// SSRで生成されたmaterialUI用のCSSを削除する https://github.com/mui-org/material-ui/blob/master/examples/nextjs/pages/_app.js
const removeJssStyles = () => {
  const jssStyles = document.querySelector('#jss-server-side')
  if (jssStyles) {
    jssStyles.parentElement?.removeChild(jssStyles)
  }
}

type PageProps = {
  relayData: null | undefined | [[string, QueryPayload]]
}

const App: FC<AppProps<PageProps> & { err: NextPageContext['err'] }> = ({
  Component,
  pageProps,
  err
}) => {
  useEffect(() => {
    removeJssStyles()
  }, [])
  const { asPath, query } = useRouter()
  useEventTracking({
    category: asPath.includes('/complete')
      ? TrackingCategory.EF_COMPLETE
      : query.tour === 'true'
      ? TrackingCategory.EF_TOUR
      : TrackingCategory.EF_NORMAL
  })
  usePageView()

  return (
    <>
      <DefaultSeo />
      <Head />
      <GlobalStyle />
      <RelayEnvironmentProvider
        environment={createEnvironment(pageProps.relayData)}
      >
        <BookmarkContextProvider
          nameSpace={nameSpace}
          max={20}
          onError={() => alert('検討リストに追加できるのは20件までです。')}
        >
          <BaseLayout>
            <SingleColumn>
              <Component {...pageProps} err={err} />
            </SingleColumn>
          </BaseLayout>
        </BookmarkContextProvider>
      </RelayEnvironmentProvider>
    </>
  )
}

export default App
