// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TrackingCategory {
  export const EF_NORMAL = 'EF_通常'
  export const EF_TOUR = 'EF_見学'
  export const EF_COMPLETE = 'EF_Complete'
  export const CV_COMPLETE = 'CV_Completed'
}

export type TrackingCategoryType =
  typeof TrackingCategory[keyof typeof TrackingCategory]
